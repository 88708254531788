@import 'config.fonts';
@import 'config.colors';

html {
  font-size: 10px;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: $fontPrimary;
  color: $black;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
input {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
  line-height: normal;
  box-sizing: border-box;
  padding: 0;
  font: unset;
}

input:focus {
  outline: unset;
}

div {
  box-sizing: border-box;
}

img {
  vertical-align: middle;
}

a {
  display: inline-block;
  color: $black;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: 0 0 0 0 white inset !important;
}

button {
  font: unset;
}
