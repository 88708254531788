@import 'config.fonts';

h1 {
  margin: 0;
  padding: 0;
  @include h1;
}

h2 {
  margin: 0;
  padding: 0;
  @include h2;
}

h3 {
  margin: 0;
  padding: 0;
  @include h3;
}

p {
  margin: 0;
  padding: 0;
  @include p;
}
