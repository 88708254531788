$blue: #428bca;
$black: #333333;
$white: #fff;
$grey: #cecece;
$red: #e90000;
$lightBlue: #edf7ff;
$greyScale02: #f8f8f8;
$lightBlueBanner: #e0f1ff;

$errorColor: $red;
$primaryColor: $blue;
