$loader--width-sm: 12rem;
$loader-dot--size-sm: 1.5rem;

$loader--width-lg: 25rem;
$loader-dot--size-lg: 2rem;

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  &--sm {
    height: $loader-dot--size-sm;
    width: $loader--width-sm;
  }

  &--lg {
    height: $loader-dot--size-lg;
    width: $loader--width-lg;
  }

  &--dot {
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 50%;
    background-color: black;
    position: absolute;

    &--sm {
      animation-name: loader-sm;
      height: $loader-dot--size-sm;
      width: $loader-dot--size-sm;
    }

    &--lg {
      animation-name: loader-lg;
      height: $loader-dot--size-lg;
      width: $loader-dot--size-lg;
    }

    &:first-child {
      background-color: #87b7db;
      animation-delay: 0.5s;
    }

    &:nth-child(2) {
      background-color: #6fa9d4;
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      background-color: #579acd;
      animation-delay: 0.3s;
    }

    &:nth-child(4) {
      background-color: #3e8cc6;
      animation-delay: 0.2s;
    }

    &:nth-child(5) {
      background-color: #267ebf;
      animation-delay: 0.1s;
    }

    &:nth-child(6) {
      background-color: #0f70b8;
      animation-delay: 0s;
    }
  }
}

@keyframes loader-sm {
  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX($loader--width-sm - $loader-dot--size-sm);
  }

  65% {
    transform: translateX($loader--width-sm - $loader-dot--size-sm);
  }

  95% {
    transform: translateX(0);
  }
}

@keyframes loader-lg {
  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX($loader--width-lg - $loader-dot--size-lg);
  }

  65% {
    transform: translateX($loader--width-lg - $loader-dot--size-lg);
  }

  95% {
    transform: translateX(0);
  }
}
