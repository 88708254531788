@import 'config.mixins';

.my-1 {
  @include margin-vertical(1rem);
}

.my-2 {
  @include margin-vertical(2rem);
}

.my-3 {
  @include margin-vertical(3rem);
}

.my-4 {
  @include margin-vertical(4rem);
}

.my-5 {
  @include margin-vertical(5rem);
}

.mx-1 {
  @include margin-horizontal(1rem);
}

.mx-2 {
  @include margin-horizontal(2rem);
}

.mx-3 {
  @include margin-horizontal(3rem);
}

.mx-4 {
  @include margin-horizontal(4rem);
}

.mx-5 {
  @include margin-horizontal(5rem);
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}
