$fontPrimary: 'Open Sans', sans-serif;

//font-weight
$fontWeight400: 400;
$fontWeight600: 600;
$fontWeight700: 700;
$fontWeight800: 800;

//font-size
$fontSize12: 1.2rem;
$fontSize13: 1.3rem;
$fontSize14: 1.4rem;
$fontSize15: 1.5rem;
$fontSize16: 1.6rem;
$fontSize20: 2rem;
$fontSize26: 2.6rem;
$fontSize33: 3.3rem;

@mixin h1 {
  font-size: $fontSize33;
  font-weight: $fontWeight800;
}

@mixin h2 {
  font-size: $fontSize26;
  font-weight: $fontWeight400;
}

@mixin h3 {
  font-size: $fontSize20;
  font-weight: $fontWeight600;
}

@mixin p {
  font-size: $fontSize14;
  font-weight: $fontWeight400;
}

@mixin p-lg {
  font-size: $fontSize16;
  font-weight: $fontWeight400;
}

@mixin button-lg {
  font-size: $fontSize15;
  font-weight: $fontWeight400;
}

@mixin button-md {
  font-size: $fontSize13;
  font-weight: $fontWeight400;
}

@mixin text-input {
  font-size: $fontSize15;
  font-weight: $fontWeight400;
}

@mixin small-text {
  font-size: $fontSize13;
  font-weight: $fontWeight400;
}
