@mixin margin-vertical($margin) {
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin margin-horizontal($margin) {
  margin-right: $margin;
  margin-left: $margin;
}

@mixin center-flex-container($direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: center;
  align-items: center;
}
