.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  top: 0;
  left: 0;
}
